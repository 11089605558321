import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import Header from "../../components/header/Header";
import Posts from "../../components/posts/Posts";
import Popup from "../../components/Popup/Popup";
import "./homepage.css";
import axios from "axios";
import zaloIcon from "../../assets/zalo.png";
import messenger from "../../assets/messenger.png";
import { phone } from "../../assets";
export default function Homepage() {
  const [showPopup, setShowPopup] = useState(false);
  const [posts, setPosts] = useState([]);
  const [active, setActive] = useState("crossFire");

  const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(
        "https://backend.hoanglecf.vn/api/posts" + search
      );
      setPosts(res.data);
    };
    fetchPosts();
  }, [search]);
  useEffect(() => {
    setShowPopup(true);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="home__setBackground">
      {showPopup && <Popup onClose={closePopup} />}
      <Header />
      <div className="home_btn">
        <button
          className={active === "crossFire" ? "active" : ""}
          onClick={() => setActive("crossFire")}
        >
          CrossFire
        </button>
        <button
          className={active === "valorant" ? "active" : ""}
          onClick={() => setActive("valorant")}
        >
          Valorant
        </button>
      </div>
      <div className="home">
        <Posts posts={posts.filter((post) => post.thumb === active)} />
      </div>
      <div id="button-contact-vr">
        <div id="zalo-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://zalo.me/0777650650"
              >
                <img src={zaloIcon} alt="zalo" />
              </a>
            </div>
          </div>
        </div>
        <div id="phone-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="tel:0777656066" aria-label="Call us at 0777656066">
                <img src={phone} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div id="messenger-vr" className="button-contact">
          <div className="phone-vr">
            <div className="phone-vr-circle-fill"></div>
            <div className="phone-vr-img-circle">
              <a href="https://www.facebook.com/messages/t/100009374322909">
                <img src={messenger} alt="messenger" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
